
import '../styles/styles.scss';
import '../../node_modules/lightgallery/scss/lightgallery.scss';
import '../../node_modules/lightgallery/scss/lg-zoom.scss';
import '../../node_modules/lightgallery/scss/lg-thumbnail.scss';
import '../../node_modules/lightgallery/scss/lg-fullscreen.scss';
import 'swiper/css/bundle';

import Alpine from 'alpinejs'
import collapse from '@alpinejs/collapse'
Alpine.plugin(collapse)
import intersect from '@alpinejs/intersect'
Alpine.plugin(intersect)

window.Alpine = Alpine
Alpine.start()

// import '../../node_modules/prettier-plugin-css-order/src/main.mjs';
// import '../../node_modules/prettier-plugin-tailwindcss/dist/index.mjs';

import Swiper from 'swiper/bundle';
globalThis.Swiper = Swiper;
import lightGallery from 'lightgallery';
globalThis.lightGallery = lightGallery;

// Plugins
import lgThumbnail from 'lightgallery/plugins/thumbnail'
globalThis.lgThumbnail = lgThumbnail;
import lgZoom from 'lightgallery/plugins/zoom'
globalThis.lgZoom = lgZoom;
import lgFullscreen from 'lightgallery/plugins/fullscreen'
globalThis.lgFullscreen = lgFullscreen;

export {
  Swiper,
  lightGallery,
  lgThumbnail,
  lgZoom,
  lgFullscreen
}

// import App from './app';


// import './general';
import './swiper';
// import './alpine';


// App.init();
//
