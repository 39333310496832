import Swiper from 'swiper/bundle';
import lightGallery from 'lightgallery';

// Plugins
import lgThumbnail from 'lightgallery/plugins/thumbnail'
import lgZoom from 'lightgallery/plugins/zoom'
import lgFullscreen from 'lightgallery/plugins/fullscreen'

//**********************************************/
// swiper fullwidth nav
//**********************************************/

var optionFullwidthNav = {
     // Optional parameters
     loop: true,
     spaceBetween: 20,
     slidesPerView: 3,
     freeMode: true,
	 watchSlidesProgress: true,
     navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
      },
}
// var sliderFullWidthNav = new Swiper('.swiper-fullwidth-section-nav', optionFullwidthNav);

let $lgSwiper = document.getElementById('lg-swipper-nav');
var optionFullwidth = {
     // Optional parameters
     loop: true,
     autoHeight: true,
     spaceBetween: 0,
     // Navigation arrows
     navigation: {
         nextEl: '.swiper-button-next',
         prevEl: '.swiper-button-prev',
     },
	 pagination: {
		 el: ".swiper-pagination",
		 type: "fraction",
	   },
     slidesPerView: 1,
    // Init lightGallery ince swiper is initilized
    on: {
        init: function () {
            const lg = lightGallery($lgSwiper, {
                plugins: [lgZoom, lgThumbnail, lgFullscreen],
            });

            // Before closing lightGallery, make sure swiper slide
            // is aligned with the lightGallery active slide
            $lgSwiper.addEventListener('lgBeforeClose', () => {
                // console.log('Index swiper > ' + lg.index);
                sliderFullWidth.slideTo(lg.index, 0)
            });
        },
    }

 }
 const sliderFullWidth = new Swiper('.swiper-fullwidth-section', optionFullwidth);




let $lgSwiperYo = document.getElementById('lg-swipper-alt');
 const swiper = new Swiper('.swiper-fullwidth', {
	 // other parameters
	 loop: true,
	  autoHeight: true,
	  spaceBetween: 0,
	 navigation: {
		 nextEl: '.swiper-button-next',
		 prevEl: '.swiper-button-prev',
	 },
	 pagination: {
		  el: ".swiper-pagination",
		  type: "fraction",
		},
	 slidesPerView: 1,

	 // Init lightGallery ince swiper is initilized
	 on: {
		 init: function () {
			 const lg = lightGallery($lgSwiperYo);

			 // Before closing lightGallery, make sure swiper slide
			 // is aligned with the lightGallery active slide
			 $lgSwiperYo.addEventListener('lgBeforeClose', () => {
				 swiper.slideTo(lg.index, 0)
			 });
		 },
	 }
 });

